.about-us-container {
    text-align: center;
    padding: 20px;
}

.creators-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.creator-card {
    border: 1px solid #ddd;
    padding: 20px;
    margin: 50px;
    width: 200px;
    box-shadow: 0px 0px 5px #aaa;
}

.creator-image {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
}

a {
    text-decoration: none;
    margin: 5px 0;
    display: block;
}
