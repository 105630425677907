.edit-playlist-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .edit-playlist-modal {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    width: 400px;
  }

  .edit-playlist-form {
    display: flex;
    flex-direction: column;
  }

  .edit-playlist-form h2 {
    margin-bottom: 10px;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .form-group label {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .form-group input,
  .form-group textarea,
  .form-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .errors {
    color: red;
    margin-bottom: 10px;
    padding-left: 0;
    list-style-type: none;
  }

  .errors li {
    margin-bottom: 5px;
  }

  .form-group button {
    padding: 8px 12px;
    background-color: #ff5500;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    margin-right: 10px;
  }

  .form-group button:last-child {
    margin-right: 0;
  }

  .browse-songs {
    margin-top: 20px;
  }

  .browse-songs h3 {
    margin-bottom: 10px;
  }

  .browse-songs div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .browse-songs button {
    padding: 4px 8px;
    background-color: #ff5500;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 12px;
    cursor: pointer;
  }
