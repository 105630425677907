.song-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.song-container > div:last-child {
  margin-bottom: 150px;
}

.song-image {
  display: flex;
  justify-content: center;
  width: 150px;
  height: 150px;
  padding: 5px;
  border-radius: 30px;
  border: solid 2px black;
}
.song-image:hover {
  box-shadow: 2px 2px 6px 4px #000000;
  cursor: pointer;
}

.song-details {
  display: flex;
  flex-direction: column;
  height: 100px;
  width: 250px;
}

.song__details__button {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
}

:hover {
  /* text-decoration: underline; */
  cursor: pointer;
}

.song-card {
  display: flex;
  justify-content: center;
  /* border: 1px solid blue; */
}

.play__button {
  border: solid 1px;
  background-color: #868686;
  border-radius: 10px;
  height: 30px;
  width: 40px;
}

.play__button:hover {
  scale: 1.1;
  background-color: #ff5500;
  cursor: pointer;
}
