.delete-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }

  .delete-modal-contents {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    width: 400px;
  }

  .delete-modal h3 {
    margin-bottom: 10px;
  }

  .delete-modal-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    align-items: center;
  }

  .delete-modal button {
    padding: 8px 12px;
    background-color: #ff5500;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    margin: 5px;
    width: 50px;
  }

  .del-playlist-yes button {
    display: flex;
    justify-content: center;
    margin-left: 5px;
  }
