body {
    background-color: white;
}
.delete-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.delete-modal-contents {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
}


.delete-modal-h3 {
    margin-bottom: 15px;
}

.delete-modal-buttons {
    display: flex;
    justify-content: center;
    flex-direction: row;

}


.delete-button,
.cancel-button {
    padding: 10px 20px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.delete-button {
    background-color: #f44336;
    color: white;
    margin-left: 10px;
}

.cancel-button {
    background-color: #e0e0e0;
    color: black;
}
