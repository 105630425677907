body {
    font-family: 'roboto', sans-serif;
}

span{
    font-weight: bold;
    padding: 0;
}

.song-detail {
    display: flex;
    flex-flow: column wrap;
    margin-bottom: 150px;
}

.song-detail-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    /* border: 2px solid orange; */
}
.song-info-box {
    display: flex;
    align-items: center;
}
.song-image {
    flex: 1;
    /* border: 2px solid red; */
}

.song-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 10%;
    height: 10%;
    /* border: 2px solid green; */
}

.song-actions>button {
    /* display: flex; */
    /* align-content: center; */
    background-color: transparent;
    border: none;
    border-radius: 30px;
    width: 50px;
    height: 70px;
    box-shadow: 0px 0px 0px 2px #7A7A7A;
    /* border: 2px solid purple; */
}
.song-pic-box .song-image {
    border: solid 2px black;
}
#song-likes{
    display: flex;
    font-weight: bolder;
    padding: 10px;
}

.song-info-details {
    flex: 1;
    width: 40%;
    /* border: 2px solid blue; */
}

.song-description {
    flex: 1;
    width: 50%;
    /* border: 2px solid red; */
}
.comment-section {
    display: flex;
    flex-flow: column wrap;
}

.song-comments {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;
    margin: 20px auto;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}

.song-comments-contents {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    margin-bottom: 20px;
    width: 100%;
    padding: 10px;
    background-color: #f8f8f8;
    border-radius: 5px;
}

.comments-user-box {
    display: flex;
    flex-direction: column;
}

.comment-button button,
.delete-comment button{
    border: solid 1px black;
    border-radius: 5px;
    padding: 5px;
    color: white;
    width: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    cursor: pointer;
    outline: none;
    border-radius: 5px;
    text-decoration: none;
    background-color: black
}

.comment-button button:hover,
.delete-comment button:hover {
    background-color: #ff5500;
}
.comment-section>h2{
    border-bottom: 2px solid grey;
}

.delete-comment{
    background-color: white;
}

.comment-user-img {
    margin-right: 10px;
}

.comment-user-img img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.like-button {
    background-color: #ff5500;
    border-radius: 50%;
}
