.user-img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
}

.user-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.update-song-button
.delete-song-button {
    padding: 5px 10px;
    background-color: #ff5500;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
}

.user-playlist {
    padding: 5px 10px;
    background-color: #ff5500;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
}

.user-playlist a {
    outline: none;
    text-decoration: none;
    color: white;
}

.song-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    padding: 10px;
}

.song-info {
    display: flex;
    align-items: center;
    border: solid 3px darkgray;
    border-radius: 10px;
    background-color: beige;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

.song-info:hover {
    background-color: lightblue;
    transform: scale(1.02);
}

.song-image {
    width: 200px;
    height: 200px;
    object-fit: cover;
    margin: 20px;
}

.song-details {
    flex: 1;
  }

  .song-details p {
    margin-bottom: 5px;
  }

  .song-info-details {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
  }

  .song-info-details .song-name {
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .song-info-details .song-artists {
    font-size: 13px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: bold;
  }
  .song-info-details .song-genre {
    font-size: 13px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .song-info-details .song-likes {
    font-size: 13px;
    margin-bottom: 5px;
    margin-top: 5px;
    color: red;
  }

  .song-buttons {
    margin: 20px;
    display: flex;
    flex-direction: row ;
    align-items: center;
  }

  .song-buttons .update-song-button a {

    outline: none;
    text-decoration: none;
    color: white;
    justify-content: center;

  }
  .song-buttons .update-song-button {
    padding: 5px 10px;
    margin-right: 10px;
    background-color: #ff5500;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
  }

  .song-buttons .delete-song-button {
    padding: 5px 10px;
    background-color: #ff5500;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    outline: none;
  }
  .song-buttons .delete-song-button button {
    font-size: 12px;
    background-color: transparent;
    text-decoration: none;
    color: inherit;
    border: none;
  }
