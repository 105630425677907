.profile-dropdown {
  position: absolute;
}

.hidden {
  display: none;
}

.home__button__icon {
  height: 50px;
  width: auto;
}

ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

li {
  position: relative;
  display: flex;
  justify-content: space-evenly;
}

.home-button {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-height: 100px;
}

#home-button {
  background-color: white;
  top: 0;
}
.header .home-button {
  max-height: 100px;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.navigation-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  /* padding: 10px; */
  margin-bottom: 50px;
  border-bottom: 2px solid lightgray;
}

.profile-button-logo {
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 25px;
  border-radius: 25px;
  height: 50px;
  width: 100%;
}

#profile-button-logo {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-self: center;
}

.profile-dropdown {
  border-radius: 10px;
  border: 2px solid #ff5500;
  right: 20px;
  top: 70px;
  width: 200px;
  text-align: start;
  background-color: white;
  padding-right: 15px;
}

#profile-button:hover {
  cursor: pointer;
}

.login-button {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  margin-left: 0;
  background-color: transparent;
  /* border: 2px solid red; */
}

.signup-button {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  margin-left: 0;
  text-decoration: none;
}

#song-button {
  padding: 5px 10px;
  background-color: #ff5500;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  outline: none;
}
#song-button > a {
  color: #ffff;
  text-decoration: none;
}

#home-button,
#song-button {
  /* padding: 5px 10px; */
  /* background-color: #000000; */
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  outline: none;
}

#home-button:hover {
  background-color: #ff550077;
}

/* #song-button:hover {
  background-color: #ff550077;
} */
