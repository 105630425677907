.signup-modal {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 300px;
  /* border: 5px solid green; */
}

h1 {
  display: flex;
  justify-content: center;
  /* border: 3px solid orange; */
}

.signup-form {
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  /* border: 2px solid red; */
}

.label {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  /* border: 3px solid black; */
}

.input {
  display: flex;
  justify-content: center;
  width: 94%;
  margin: 5px;
  /* border: 2px solid red; */
}
.file-input {
  display: flex;
  justify-content: space-between;
  padding-left: 5px;
  padding-right: 5px;
  /* border: 5px solid black; */
}

#user-image {
  display: flex;
  border: 2px solid orange;
}
#signup-submit-div {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  /* border: 5px solid black; */
}
#signup-submit {
  display: flex;
  justify-content: center;
  width: 50%;
  background-color: #ff5500;
  border: 2px solid #ff5500;
  font-weight: bold;
  color: white;
  margin-top: 10px;
  padding: 5px;
  border-radius: 15px;
  box-shadow: 2px 2px 0px 0px #ff5500;
}
