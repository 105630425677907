.delete-modal {
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
}
.delete-modal-contents {
    background-color: white;
    display: flex;
    flex-direction: column;
}
.delete-modal-contents h3 {
    background-color: white;
}

.delete-modal-buttons {
    display: flex;
    justify-content: center;
    margin: 20px;
    flex-direction: column;
    align-items: center;
}
.delete-button,
.cancel-button {
    padding: 10px 20px;
    margin: 10px;
    border: none;
    width: 100px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.delete-button {
    margin-left: 10px;
}

.cancel-button {
    background-color: black;
    color: white;
}
