.login-modal {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 300px;
}
.login-form {
  display: flex;
  flex-direction: column;
}

#username-email {
  display: flex;
  justify-content: center;
  align-content: flex-start;
  outline: none;
  outline-offset: 2px;
  padding-bottom: 10px;
  width: 300px;
}

#password {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  width: 300px;
}

#login-button {
  width: 50%;
  background-color: #ff5500;
  border: 2px solid #ff5500;
  font-weight: bold;
  color: white;
  margin-top: 10px;
  padding: 5px;
  border-radius: 15px;
  box-shadow: 2px 2px 0px 0px #ff5500;
  /* border: 2px solid red; */
}
button,
#login-button-drop {
  padding: 5px 10px;
  background-color: #ff5500;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  outline: none;
}
button,
#signup-button-drop {
  padding: 5px 10px;
  background-color: #ff5500;
  color: white;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  /* outline-color: white; */
}

#login-submit {
  /* display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  height: 50px; */
}

#div-login-submit {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  width: 92%;
  padding-left: 10px;
  background-color: white;
  color: grey;
}

.error-list {
  margin-top: 10px;
  padding: 10px;
  color: red;
  font-size: x-small;
  font-weight: bold;
  border-radius: 4px;
}

.demo-login {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  margin-bottom: 10px;
}
