.create-comment-modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
}

.create-comment-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.create-comment-contents {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}
.create-comment-contents #login-text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 60px;
}
.submit-comment-button {
    border: solid 1px black;
    border-radius: 5px;
    padding: 5px;
    color: white;
    width: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 100px;
    cursor: pointer;
    outline: none;
    border-radius: 5px;
    text-decoration: none;
    background-color: black
}

.submit-comment-button:hover {
    background-color: #ff5500;
}

.comment-contents {
    display: flex;
}
.create-comment-form {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.create-label {
    display: flex;
    flex-direction: column;
}

.error-message {
    color: red;
}
